import {PlatformDomains} from "@platform/cj-platform-navigation";

export interface AppConfig {
    env: string
    memberUrl: string
    idProvider: string
    apiUrl: string
    promotionalApiUrl: string
    platformDomains?: Partial<PlatformDomains>
}

export const fetchConfig = async (): Promise<AppConfig> => {
    const response = await fetch('/config.json')
    return response.json()
}
