import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import * as serviceWorker from './serviceWorker';
import {fetchConfig} from "./appConfig";

const App = React.lazy(() => import('./App'))

const init = async () => {
    const appConfig = await fetchConfig()

    ReactDOM.render(
        <React.Suspense fallback={<></>}>
            <App appConfig={appConfig} />
        </React.Suspense>,
        document.getElementById('root'));
}

init().catch(console.error)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
